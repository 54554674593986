import React from "react";

export default function Footer() {
	return (
		<footer className='footer'>
			<div className='container'>
				<div className='footer__inner'>
					<div className='footer__divider'></div>
					<p>Created by <a href='https://twitter.com/ninalimpi' target='_blank' rel='noreferrer noopener'>Katerina Limpitsouni</a></p>
					<p>Code / Design by <a href='https://twitter.com/anges244' target='_blank' rel='noreferrer noopener'>Aggelos Gesoulis</a></p>
					<p className='footer__cp'>© {new Date().getFullYear()} · Katerina Limpitsouni  · All rights reserved </p>
				</div>
			</div>
		</footer>
	)
}
